import { render, staticRenderFns } from "./ProjectMenu.vue?vue&type=template&id=323c8a40&scoped=true&"
import script from "./ProjectMenu.vue?vue&type=script&lang=js&"
export * from "./ProjectMenu.vue?vue&type=script&lang=js&"
import style0 from "./ProjectMenu.vue?vue&type=style&index=0&lang=scss&rel=stylesheet%2Fscss&"
import style1 from "./ProjectMenu.vue?vue&type=style&index=1&id=323c8a40&lang=scss&rel=stylesheet%2Fscss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "323c8a40",
  null
  
)

export default component.exports