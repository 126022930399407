<template>
  <div ref="projectMenu">
    <div class="view-container mb-3">
      <div class="view">
        <div class="view-body view-body-padding d-flex align-items-center justify-content-between">
          <div class="d-flex  align-items-center">
            <el-breadcrumb
                class="breadcrumbMenu"
                separator-class="el-icon-arrow-right"
                v-loading="refreshProject"
            >
              <el-breadcrumb-item>
                <router-link :to="{name: 'Project'}">{{ $t('system.projects_list') }}</router-link>
              </el-breadcrumb-item>
              <template v-for="(item, index) in breadcrumbs">
                <el-tooltip
                    :content="item.title"
                    :key="item.title + index"
                    effect="dark"
                    placement="bottom"
                    v-if="item.id || item.title !== ''"
                >
                  <el-breadcrumb-item>
                    <template v-if="index+1 === _self['breadcrumbs'].length">
                      <router-link
                          :to="{ name: $route.name, params: { projectId: item.id }}"
                          v-if="item.id"
                      >
                        {{ item.title }}
                      </router-link>
                    </template>
                    <template v-else>
                      <router-link
                          :to="{ name: item.name, params: { projectId: item.id }}"
                          v-if="item.id"
                      >
                        {{ item.title }}
                      </router-link>
                    </template>
                  </el-breadcrumb-item>
                </el-tooltip>
              </template>
            </el-breadcrumb>
            <!--              <el-dropdown trigger="click" class="pr-3 pl-3" @command="changeStatus">-->
            <!--                <el-button :type="statuses[currenetStatus] ? 'primary' : 'info'" size="small"-->
            <!--                           :loading="loadingStatuses">-->
            <!--                  {{statuses[currenetStatus] ? statuses[currenetStatus].name : 'Set status'}}-->
            <!--                  <i class="el-icon-arrow-down el-icon&#45;&#45;right"></i>-->
            <!--                </el-button>-->
            <!--                <el-dropdown-menu slot="dropdown">-->
            <!--                  <el-dropdown-item v-for="status in statuses" :command="status.id" :key="status.id">{{status.name}}-->
            <!--                  </el-dropdown-item>-->
            <!--                </el-dropdown-menu>-->
            <!--              </el-dropdown>-->
          </div>
          <div class="d-flex flex-wrap justify-content-end">
            <template v-if="ifHasPermission(['pm-add-issues'])">
              <transition-group name="rowtrans">
                <el-button
                    :key="item.id"
                    @click="openModalWithCurrentTracker(item.id, item.translation)"
                    class="m-1"
                    size="small"
                    type="primary"
                    v-for="item in trackers"
                >
                  <font-awesome-icon icon="plus" />
                  {{ $t(item.translation) }}
                </el-button>
              </transition-group>
            </template>
            <el-dropdown
                class="m-1"
                trigger="click"
                v-if="ifHasPermission(['pm-create-subproject', 'pm-delete-project'])"
            >
              <el-button
                  size="small"
                  type="info"
              >
                {{ $t('system.more') }}
                <i class="el-icon-arrow-down el-icon--right"></i>
              </el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                    @click.native="goToCreateSubproject"
                    v-if="ifHasPermission(['pm-create-subproject'])"
                >
                  {{ $t('project.create_subproject') }}
                </el-dropdown-item>
                <!--                  <el-dropdown-item @click.native="goToInvoiceProject" v-if="ifHasPermission(['create-invoice'])">-->
                <!--                    {{$t('project.invoice_project')}}-->
                <!--                  </el-dropdown-item>-->
                <!--                  <el-dropdown-item @click.native="pinProject">-->
                <!--                    {{isProjectPinned ? $t('project.unpin') : $t('project.pin')}}-->
                <!--                  </el-dropdown-item>-->
                <!--TODO:Add new permission to seeder 'pm-delete-project'-->
                <el-dropdown-item
                    @click.native="deleteProject"
                    v-if="ifHasPermission(['pm-delete-project'])"
                >{{ $t('project.delete') }}
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
      </div>
    </div>

    <div class="view-container mb-3">
      <div class="view">
        <div
            :key="projectId"
            class="view-body d-flex justify-content-between"
        >
          <el-menu
              :default-active="activeLink"
              :router="true"
              class="submenu"
              mode="horizontal"
          >
            <el-menu-item :index="'/project/' + projectId">
              {{ $t('system.overview') }}
            </el-menu-item>
            <template v-if="ifHasPermission(['issue-list'])">
              <el-menu-item
                  :index="'/project/' + projectId + '/list-tracker/' + item.id"
                  :key="item.id"
                  v-for="item in trackers"
              >
                {{ $t(item.translation) }}
              </el-menu-item>
            </template>
            <el-menu-item
                :index="'/project/' + projectId + '/activity'"
                v-if="ifHasPermission(['pm-view-activities'])"
            >
              {{ $t('system.activity') }}
            </el-menu-item>
            <!--            <el-menu-item v-if="ifHasPermission(['pm-generate-reports'])"-->
            <!--                          :index="'/project/' + projectId + '/report'">-->
            <!--              {{ $t('system.reports') }}-->
            <!--            </el-menu-item>-->
            <el-menu-item
                :index="'/project/' + projectId + '/report_v2'"
                v-if="ifHasPermission(['pm-generate-reports'])"
            >
              {{ $t('system.reports') }}
            </el-menu-item>
            <el-menu-item
                :index="'/project/' + projectId + '/member'"
                v-if="ifHasPermission(['pm-member-read'])"
            >
              {{ $t('system.members') }}
            </el-menu-item>
            <!--            <el-menu-item v-permission="['pm-edit-project']" :index="'/project/' + projectId + '/setting/edit'">-->
            <!--              <router-link :to="'/project/' + projectId + '/setting/edit'">-->
            <!--                {{ $t('system.settings') }}-->
            <!--              </router-link>-->
            <!--            </el-menu-item>-->
          </el-menu>
          <div class="d-flex align-items-center">
            <transition name="bounce">
              <i
                  :key="isProjectPinned"
                  :style="{color: isProjectPinned ? '#edb311' : '#c9c9c7'}"
                  @click="togglePinProject"
                  class="el-icon-star-on pointer"
                  style="font-size: 30px"
              />
            </transition>
          </div>
        </div>
      </div>
    </div>
    <transition
        mode="out-in"
        name="fade"
    >
      <router-view :key="activeLink"></router-view>
    </transition>
    <!--<issue-create :goesFrom="$route.path" v-if="showIssueEdit" @close="closeCreateModal()" mode="create"></issue-create>-->
  </div>
</template>

<script>
import axios from 'axios';
import swal from 'sweetalert2';
import {mapActions, mapState} from 'vuex';
import notification from '../../../../notification/notify';

export default {
  name: 'ProjectMenu',
  props: {
    projectId: Number,
  },
  data() {
    return {
      model: {},
      resource: 'project',
      redirect: '/',
      mode: {},
      breadcrumbs: [],
    };
  },
  watch: {
    projectId: {
      immediate: true,
      async handler() {
        await this.setProjectId(this.projectId);
        this.fetchBreadcrumbs();
        // this.getCurrentStatus();
        // if (this.ifHasPermission(['issue-list'])) {
        //   this.fetchTrackers()
        // }
      },
    },
  },
  beforeDestroy() {
    this.setProjectId(null);
  },
  computed: {
    ...mapState('favorite', ['projects']),
    ...mapState('trackers', ['trackers']),
    refreshProject() {
      if (this.$store.getters['project/refreshProject']) {
        this.fetchBreadcrumbs();
        // this.getCurrentStatus();
        // this.fetchTrackers()
        this.$store.commit('project/REFRESH_PROJECT', false);
      }
      return this.$store.getters['project/refreshProject'];
    },
    activeLink() {
      return this.$route.path;
    },
    isProjectPinned() {
      return !!this.projects.find(item => item.id === this.projectId);
    },
  },
  methods: {
    ...mapActions('permission', ['setProjectId']),
    ...mapActions('favorite', ['setFavoriteProject', 'unsetFavoriteProject']),
    ...mapActions('trackers', ['fetchTrackers']),
    openModalWithCurrentTracker(id, translation) {
      this.$store.commit('modal/OPEN_CREATE_MODAL', {id: id, translation: translation});
    },
    fetchBreadcrumbs() {
      axios.get('project/get_name_breadcrumbs', {
        params: {
          projectId: this.projectId,
        },
      }).then((response) => {
        this.breadcrumbs = response.data.breadcrumbs;
      })
          .catch((response) => {
            notification.notify(
                this.$t('notify.error'),
                this.$t(response.data.message),
                'error');
          });
    },
    async togglePinProject() {
      try {
        if (this.isProjectPinned) {
          await this.unsetFavoriteProject(this.projectId);
        } else {
          await this.setFavoriteProject(this.projectId);
        }
      } catch (error) {
        notification.notify(
            this.$t('notify.error'),
            this.$t(error.message),
            'error');
      }
    },
    goToCreateSubproject() {
      this.$router.push({name: 'Create SubProject', params: {projectId: this.projectId}});
    },
    // goToInvoiceProject() {
    //   this.$router.push({name: 'Create Invoice from PRO', params: {projectId: this.projectId}})
    // },
    deleteProject() {
      let vm = this;
      swal.fire({
        target: vm.$refs.root,
        title: vm.$t('system.remove'),
        text: vm.$t('system.if_you_sure'),
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: vm.$t('system.yes'),
        cancelButtonText: vm.$t('system.no'),
        focusCancel: true,
      }).then((result) => {
        if (result.value) {
          axios.delete(`project/${vm.projectId}`)
              .then(response => {
                notification.notify(
                    vm.$t('notify.deleted'),
                    vm.$t('notify.project_was_deleted'),
                    'success');
                if (vm.isProjectPinned) {
                  vm.$store.commit('SET_REFRESH_PINNED_PROJECTS', true);
                }
                vm.$router.push({name: 'Project'});
              })
              .catch(error => {
                notification.notify(
                    vm.$t('notify.error'),
                    vm.$t(error.message),
                    'error');
              });
        } else if (
            // Read more about handling dismissals
            result.dismiss === swal.DismissReason.cancel
        ) {
          // swalWithBootstrapButtons(
          //   vm.$t('system.cancelled'),
          //   vm.$t('system.safe'),
          //   vm.$t('system.error')
          // )
          // vm.$message({dangerouslyUseHTMLString: true, message: vm.$t('system.cancelled'), type: 'success'});
        }
      });
    },
  },
};
</script>
<style
    lang="scss"
    rel="stylesheet/scss"
>
.breadcrumbMenu {
  .el-breadcrumb__inner {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: inline-block;
    max-width: 400px;
  }
}

.el-breadcrumb__item:last-child {
  .el-breadcrumb__inner a {
    /*font-size: 16px;*/
    font-weight: 600 !important;
  }
}
</style>
<style
    lang="scss"
    rel="stylesheet/scss"
    scoped
>
.el-menu {
  .el-menu-item {
    text-align: center;
    padding: 0 20px !important;
  }
}

.view-body-padding {
  padding: calc(20px - 0.25rem) !important;
}
</style>
